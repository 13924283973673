<template>
  <el-card class="intention-card">
    <div class="bc" style="margin-top: 0px;">
      <div class="bc-l"></div>
      <div class="bc-t">意向客户统计</div>
    </div>
    <div class="total-list">
      <div class="total">
        <div class="total-type">店铺</div>
        <div class="total-1">总:{{ alltotal.all1 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month1 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day1 }}</div>
        </div>
      </div>
      <div class="total">
        <div class="total-type">源码</div>
        <div class="total-1">总:{{ alltotal.all2 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month2 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day2 }}</div>
        </div>
      </div>
      <div class="total">
        <div class="total-type">需求</div>
        <div class="total-1">总:{{ alltotal.all3 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month3 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day3 }}</div>
        </div>
      </div>
      <div class="total">
        <div class="total-type">设计</div>
        <div class="total-1">总:{{ alltotal.all4 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month4 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day4 }}</div>
        </div>
      </div>
      <div class="total">
        <div class="total-type">服务</div>
        <div class="total-1">总:{{ alltotal.all5 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month5 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day5 }}</div>
        </div>
      </div>
      <div class="total">
        <div class="total-type">文档</div>
        <div class="total-1">总:{{ alltotal.all6 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month6 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day6 }}</div>
        </div>
      </div>
      <div class="total">
        <div class="total-type">兼职</div>
        <div class="total-1">总:{{ alltotal.all7 }}</div>
        <div class="total-2">
          <div>本月:{{ monthtotal.month7 }}</div>
          <div style="margin-top: 10px">今日:{{ daytotal.day7 }}</div>
        </div>
      </div>
    </div>
    <div class="bc">
      <div class="bc-l"></div>
      <div class="bc-t">意向客户</div>
    </div>
    <el-form class="searchFrom square-input-warp square-btn-warp" size="small" :inline="true"
             lable-width="80px" :model="searchForm">
      <el-form-item label="意向类型:">
        <el-select v-model="searchForm.etype" placeholder="全部">
          <template v-for="etype in etypes">
            <el-option :key="etype.name" :label="etype.name" :value="etype.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="状态:">
        <el-select v-model="searchForm.readflag" placeholder="全部">
          <template v-for="readflag in readflags">
            <el-option :key="readflag.name" :label="readflag.name"
                       :value="readflag.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-button size="small" type="primary" class="sctp-square" @click="queryintention">搜索
      </el-button>
      <el-button size="small" class="sctp-square" native-type="reset">重置</el-button>
    </el-form>
    <el-row class="sctp-bg-f8 sctp-pad-tb10">
      <el-col :span="4">
        <div class="sctp-tc">客户</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">类型</div>
      </el-col>
      <el-col :span="7">
        <div class="sctp-tc">查看对象</div>
      </el-col>
      <el-col :span="4">
        <div class="sctp-tc">查看时间</div>
      </el-col>
      <el-col :span="3">
        <div class="sctp-tc">状态</div>
      </el-col>
      <el-col :span="1">
        <div class="sctp-tc">备注</div>
      </el-col>
      <el-col :span="3">
        <div class="sctp-tc">操作</div>
      </el-col>
    </el-row>
    <template v-if="intentionData && intentionData.length > 0">
      <template v-for="intention in intentionData">
        <div :key="intention.id" class="intention-item">
          <el-row type="flex" align="middle" class="sctp-pad-tb15">
            <el-col :span="4">
              <div class="sctp-tc">
                <div class="r-flex">
                  <div class="r-img"><img :src="intention.headimage" v-if="intention.headimage">
                  </div>
                  <div class="r-title" style="line-height: 55px">{{ intention.usernice }}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="sctp-tc">{{ intention.etype | eTypeFilter }}</div>
            </el-col>
            <el-col :span="7">
              <div class="sctp-tc">
                <div class="r-flex">
                  <div class="r-img"><img :src="intention.imgurl"></div>
                  <div class="r-title">{{ intention.title }}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="sctp-tc">
                {{ intention.createtime }}
                <div style="margin-top: 10px;">用户查看了您的联系方式</div>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="sctp-tc">
                <div class="sctp-tc">{{ intention.readflag | readFlagFilter }}</div>
              </div>
            </el-col>
            <el-col :span="1">
              <div class="sctp-tc" v-if="intention.remarks">
                <el-popover
                  placement="top-end"
                  width="500"
                  trigger="hover"
                  :content="intention.remarks">
                  <i slot="reference" class="el-icon-date" style="font-size: 20px"></i>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="sctp-mar-b5 sctp-tc">
                <el-button
                  size="mini"
                  type="primary"
                  @click="viewPhoneDialogConfig.onViewClick(intention)"
                >
                  联系他
                </el-button>
              </div>
              <div class="sctp-mar-b5 sctp-tc" v-if="intention.readflag === 0">
                <el-button
                  size="mini"
                  type="success"
                  @click="readFlag(intention.joinid)"
                >
                  标记已读
                </el-button>
              </div>
              <div class="sctp-mar-b5 sctp-tc">
                <el-button
                  size="mini"
                  type="warning"
                  @click="remarks(intention)"
                >
                  备注
                </el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="empty-text">暂无数据</div>
    </template>
    <el-dialog
      center
      title="联系方式"
      width="500px"
      :visible.sync="viewPhoneDialogConfig.show"
    >
      <div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">手机号码：</div>
          <div>{{ userinfo.phone || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
          <div>{{ userinfo.email || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">QQ号：</div>
          <div>{{ userinfo.qqcode || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信号：</div>
          <div>{{ userinfo.wxcode || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
          <div>
            <template v-if="userinfo.wxqrcode">
              <el-image
                :src="userinfo.wxqrcode"
                style="width: 80px;height: 80px;"
                fit="contain"
              ></el-image>
            </template>
            <template v-else>
              未设置
            </template>
          </div>
        </div>
        <div class="fz-12 fc-info">
          注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="查看联系方式"
      :visible.sync="viewPhoneDialogConfig.showPayDialog">
      <pay :pay-amount="1"
           :can-use-reward="true"
           @pay="viewPhoneDialogConfig.onPay"></pay>
    </el-dialog>
    <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
      <el-pagination
        :current-page="pageConfig.page"
        :page-size="pageConfig.limit"
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :page-sizes="pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="pageConfig.total">
      </el-pagination>
    </div>
  </el-card>
</template>
<script>
import {shop} from "../../../apis";

const eType = [
  {name: '店铺', type: 1},
  {name: '源码', type: 2},
  {name: '需求', type: 3},
  {name: '设计', type: 4},
  {name: '服务', type: 5},
  {name: '文档', type: 6},
  {name: '兼职', type: 7},
]
const readFlag = [
  {name: '未读', type: 0},
  {name: '已读', type: 1},
]
export default {
  name: 'intentioncustomer',
  components: {
    pay: () => import('@CMP/common/BuyPay'),
  },
  data() {
    return {
      userid: null,
      price: null, // 查看联系方式费用
      searchForm: {
        etype: null,
      },
      intentionData: [],
      userinfo: {},
      alltotal: {},
      monthtotal: {},
      daytotal: {},
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.queryjoinrecord()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.queryjoinrecord()
        }
      },
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
        onViewClick: (intention) => {
          this.userid = intention.userid;
          if (intention.showContact) {
            this.userinfo = intention;
            this.viewPhoneDialogConfig.show = true;
          } else {
            this.userinfo = {};
            this.viewPhoneDialogConfig.confirmPay(intention);
          }
        },
        confirmPay: (intention) => {
          this.$confirm(`<div>
              <div class="sctp-red sctp-font-18">${this.price && this.price.toMoney() || 0}元</div>
              <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
              <div class="flex flex-center">
              <div class="sctp-mar-r10">${intention && intention.hasPhone
            ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
              <div class="sctp-mar-r10">${intention && intention.hasEmail
            ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}邮箱</div>
              <div class="sctp-mar-r10">${intention && intention.hasQqCode
            ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}QQ</div>
              <div class="sctp-mar-r10">${intention && intention.hasWxCode
            ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}微信</div>
              </div>
              </div>`, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '支付',
            cancelButtonText: '取消',
            center: true,
            title: '查看联系方式需支付服务费',
          }).then(() => {
            this.viewPhoneDialogConfig.showPayDialog = true;
          })
        },
        onPay: ({payWay}) => {
          shop.viewContactInformation({
            userId: this.user.userId,
            shopId: this.userid,
            payWay: payWay,
            eType: 8,
            eId: this.userid
          }).then((res) => {
            this.viewPhoneDialogConfig.showPayDialog = false;
            this.queryjoinrecord();
            this.userinfo = res.data;
            this.viewPhoneDialogConfig.show = true;
          })
        }
      },
    }
  },
  filters: {
    eTypeFilter(type) {
      // 在过滤 器当中不能引用当前实例 this   this.payTypeOptions 错误的
      const payObj = eType.find(obj => obj.type === type)
      // 非空返回类型名称
      return payObj ? payObj.name : null
    },
    readFlagFilter(type) {
      // 在过滤 器当中不能引用当前实例 this   this.payTypeOptions 错误的
      const payObj = readFlag.find(obj => obj.type === type)
      // 非空返回类型名称
      return payObj ? payObj.name : null
    }
  },
  methods: {
    queryjoinrecord() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      const {pageConfig: {page, limit}} = this;
      this.$request.post({
        reqcode: '1170',
        reqdesc: '我的意向客户',
        ...searchForm,
        userId: this.user.userId,
        page: page,
        limit: limit
      }).then(res => {
        const {data, count = 0} = res;
        data.dataList.forEach(item => {
          if (item.createtime) {
            item.createtime = this.dateToString(new Date(item.createtime), 'yyyy-MM-dd HH:mm:ss')
          }
        });
        this.intentionData = data.dataList;
        this.alltotal = data.alltotal;
        this.daytotal = data.daytotal;
        this.monthtotal = data.monthtotal;
        this.price = data.price;
        this.pageConfig.total = count;
      })
    },
    queryintention() {
      this.queryjoinrecord();
    },
    readFlag(joinid) {
      this.$request.post({
        reqcode: '1235',
        reqdesc: '意向客户标记已读',
        "joinid": joinid
      }).then(res => {
        this.queryjoinrecord();
        this.$message.success("标记已读成功");
      });
    },
    remarks(res) {
      this.$prompt('请输入备注', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\s\S]{0,50}$/,
        inputErrorMessage: '最多可输入50个字符',
        inputValue: res.remarks
      }).then(({value}) => {
        this.$request.post({
          reqcode: '1236',
          reqdesc: '添加意向备注',
          "remarks": value,
          "joinid": res.joinid
        }).then(res => {
          this.queryjoinrecord();
          this.$message.success("标记已读成功");
        });
      })
    }
  },
  computed: {
    etypes() {
      return [
        {name: '全部', value: null},
        {name: '店铺', value: 1},
        {name: '源码', value: 2},
        {name: '需求', value: 3},
        {name: '设计', value: 4},
        {name: '服务', value: 5},
        {name: '文档', value: 6},
        {name: '兼职', value: 7}
      ]
    },
    readflags() {
      return [
        {name: '全部', value: null},
        {name: '未读', value: 0},
        {name: '已读', value: 1},
      ]
    }
  },
  beforeMount() {
    this.queryjoinrecord()
  }
}
</script>

<style scoped lang="scss">
.intention-card /deep/ .el-input {
  width: 110px !important;
}

.intention-card .r-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.r-flex {
  display: flex;
  text-align: left;
}

.r-flex .r-img {
  width: 25%;
  margin-left: 30px;
  margin-right: 10px;
}

.r-flex .r-title {
  width: 75%;
  margin-right: 10px;
}

.intention-card /deep/ .el-card__body {
  padding: 12px;
}

.intention-detail-dialog /deep/ .el-dialog {
  width: 80%;
}

.intention-detail-dialog /deep/ .el-dialog__body {

  overflow-y: auto;
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

.intention-item {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  vertical-align: middle;
}

.sctp-tc12 {
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
}

/*@formatter:on*/
.intention-card .bc {
  display: flex;
  font-size: 15px;
  line-height: 20px;
  margin: 20px 0px;
}

.intention-card .bc-l {
  width: 3px;
  height: 20px;
  background-color: darkorange;
  margin-right: 10px;
}

.intention-card .total-list {
  display: flex;
}

.intention-card .total {
  width: 15%;
  margin: 0px 2px;
  padding: 7px;
  height: 100px;
  text-align: center;
  position: relative;
  background-color: rgb(245, 237, 225);
}

.intention-card .total-type {
  font-size: 14px;
  margin-bottom: 7px;
}

.intention-card .total-1 {
  float: left;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
}

.intention-card .total-2 {
  position: absolute;
  right: 10px;
  margin-top: 5px;
}
</style>
